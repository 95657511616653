import { logoURL, PAGE_TITLE, DISPLAY_NAME } from '~/constants/constants';
import {
  setLogo,
  setDisplayName,
  setIsEmojiOn,
  setStyles,
  setTitle, setACHSelectionType, setPasswordChangeAvailability,
} from '~/actions/app';
import {
  getLogo,
  getIsEmojiOn,
  getStyles,
  getTitle,
  getACHSelectionType, getPasswordChangeAvailability,
} from '~/services/company';
import { ACH_SELECTION_TYPES } from '~/constants/accounts';

export const getTitleFor = companyName => companyName
  ? `${companyName} Investments`
  : PAGE_TITLE;

export const dispatchCompanyDefaults = (dispatch, settings) => {
  if (settings) {
    dispatch(setTitle(getTitleFor(getTitle(settings))));
    dispatch(setStyles(getStyles(settings)));
    dispatch(setLogo(getLogo(settings)));
    dispatch(setDisplayName(getTitle(settings)));
    dispatch(setIsEmojiOn(getIsEmojiOn(settings)));
    dispatch(setACHSelectionType(getACHSelectionType(settings)));
    dispatch(setPasswordChangeAvailability(getPasswordChangeAvailability(settings)));
  } else {
    dispatch(setTitle(PAGE_TITLE));
    dispatch(setStyles(''));
    dispatch(setLogo(logoURL));
    dispatch(setDisplayName(DISPLAY_NAME));
    dispatch(setIsEmojiOn(true));
    dispatch(setACHSelectionType(ACH_SELECTION_TYPES.MANUAL));
    dispatch(setPasswordChangeAvailability(true));
  }
};
