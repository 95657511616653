import { useCallback, useEffect, RefObject, useMemo } from 'react';

export const useOutsideEvent = <T extends HTMLElement>(
  targetRef: RefObject<T>,
  event: 'click' | 'touchStart',
  cb: VoidFunction) => {
  const checkIfTargetElementOutside = useCallback((node: Node) => {
    return !!targetRef.current && !targetRef.current.contains(node);
  }, [targetRef]);

  const listener = useCallback((e: MouseEvent) => {
    if (checkIfTargetElementOutside(e.target) && cb) {
      void cb();
    }
  }, [targetRef, cb]);

  const mappedEvent = useMemo(() => {
    switch (event) {
      case 'touchStart':
        return 'touchstart';
    }

    return event;
  }, [event]);

  useEffect(() => {
    document.addEventListener(mappedEvent, listener);

    return () => {
      document.removeEventListener(mappedEvent, listener);
    };
  }, [listener, mappedEvent]);
};
