import { entries } from 'lodash';

export type ValidationResponse = string;

const validateField = (name: string, value: any, validators: { [key: string]: (val: any) => ValidationResponse }) => {
  const validator = validators[name];

  if (validator) {
    const error = validator(value);

    if (error) {
      return error;
    }
  }

  return '';
};

export const getFieldValidator = <T extends object> (validators: { [key in keyof Partial<T>]: (val: T[keyof T]) => ValidationResponse }) => (name: keyof T, value: T[keyof T]) => {
  const validator = validators[name];

  if (validator) {
    const error = validator(value);

    if (error) {
      return error;
    }
  }

  return '';
};

export const getFormValidator = <T extends object> (formValidator: { [key in keyof Partial<T>]: (val: T[keyof T]) => ValidationResponse }) => (data: T): { [key in keyof Partial<T>]: ValidationResponse } => {
  const errors = {};

  entries(data).forEach(([name, value]) => {
    const error = validateField(name, value, formValidator);

    if (error) {
      errors[name] = error;
    }
  });

  return errors;
};
