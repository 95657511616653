import numeral from 'numeral';

export const formatMoney = (value, format = '0,0.00') => numeral(value).format(format);

export const parseMoney = (amount: string) => Number(amount.split(',').join(''));

export const format = formatMoney;
export const parse = parseMoney;

export default format;
