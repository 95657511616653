import styled from 'styled-components';
import { PlainAccount } from '~/features/ACH/ui/PlainAccount';

import * as CommonStyles from '~/ui/kit/molecules/Input/Input.styled';
import { gaps } from '~/ui/kit/constants/sizes';
import { baseFont } from '~/ui/kit/constants/fonts';

export const Container = styled('div')`

`;

export const Plain = styled(PlainAccount)`
  ${CommonStyles.border};
  ${CommonStyles.borderColor};
  ${CommonStyles.boxShadow};
  padding: ${gaps.m - CommonStyles.INPUT_BORDER_WIDTH}px ${gaps.m - CommonStyles.INPUT_BORDER_WIDTH}px;
  height: auto;
  ${baseFont};
`;
