import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ACHRelationship } from '@models/transfers';

import { removeACHAction } from '~/entities/ach';
import { AccountList, AccountSelect, isExternalAccountsFactory, useAccountsValue } from '~/features/ACH';

import * as S from './ACH.styled';

export enum ACHStateType {
  PLAIN,
  SELECT,
  LIST,
  LOCKED,
  MANUAL,
}

type PropsByType = {
  type: ACHStateType.LOCKED;
  achRelationship: ACHRelationship;
  reason?: string;
} | {
  type: ACHStateType.MANUAL;
  achRelationship?: never;
} | {
  type: ACHStateType.PLAIN;
  achRelationship: ACHRelationship;
  removable?: boolean
} | {
  type: ACHStateType.SELECT;
  achRelationship: ACHRelationship;
  Before?: React.ReactNode;
  onACHSelect: (achNumber: string) => void;
  options?: ACHRelationship[]
} | {
  type: ACHStateType.LIST;
  Before?: React.ReactNode;
  onACHSelect: (achNumber: string) => void;
  options?: ACHRelationship[];
  achRelationship?: ACHRelationship;
}

type Props = {
  onError?: VoidFunction;
  className?: string;
} & PropsByType;

export const ACHWidget: FC<Props> = (props) => {
  const dispatch = useDispatch();

  const achAccounts = useAccountsValue();
  const isExternalAccount = useCallback(isExternalAccountsFactory(achAccounts), [achAccounts]);

  const onRemove = useCallback(() => {
    dispatch(removeACHAction());
  }, []);

  const content = useMemo(() => {
    switch (props.type) {
      case ACHStateType.LOCKED: {
        return (
          <S.Plain {...props.achRelationship} isExternal={isExternalAccount(props.achRelationship)} onRemove={onRemove} isRemovable={false} />
        );
      }
      case ACHStateType.PLAIN: {
        return <S.Plain {...props.achRelationship} isExternal={isExternalAccount(props.achRelationship)} onRemove={onRemove} isRemovable={props.removable}/>;
      }
      case ACHStateType.SELECT: {
        return (
          <AccountSelect
            accounts={props.options}
            selectedAccount={props.achRelationship}
            onSelect={props.onACHSelect}
            before={props.Before}
          />
        );
      }
      case ACHStateType.LIST: {
        return (
          <AccountList
            activeAccount={props.achRelationship?.account_number}
            accounts={props.options}
            onSelect={props.onACHSelect}
            before={props.Before}
          />
        );
      }
    }
  }, [props.type, props.achRelationship, props.options]);

  return (
    <S.Container className={props.className}>
      {content}
    </S.Container>
  );
};
