import validator from "validator";
import moment from "moment-timezone";

export const taxIdTypes = [
  {
    name: "USA Social Security Number",
    value: "USA_SSN",
  },
  {
    name: "Argentina CUIT",
    value: "ARG_AR_CUIT",
  },
  {
    name: "Australian Tax File Number",
    value: "AUS_TFN",
  },
  {
    name: "Australian Business Number",
    value: "AUS_ABN",
  },
  {
    name: "Bolivia NIT",
    value: "BOL_NIT",
  },
  {
    name: "Brazil CPF",
    value: "BRA_CPF",
  },
  {
    name: "Chile RUT",
    value: "CHL_RUT",
  },
  {
    name: "Colombia NIT",
    value: "COL_NIT",
  },
  {
    name: "Costa Rica NITE",
    value: "CRI_NITE",
  },
  {
    name: "Germany Tax ID (Identifikationsnummer)",
    value: "DEU_TAX_ID",
  },
  {
    name: "Dominican Republic RNC",
    value: "DOM_RNC",
  },
  {
    name: "Ecuador RUC",
    value: "ECU_RUC",
  },
  {
    name: "France SPI (Reference Tax Number)",
    value: "FRA_SPI",
  },
  {
    name: "UK UTR (Unique Taxpayer Reference)",
    value: "GBR_UTR",
  },
  {
    name: "UK NINO (National Insurance Number)",
    value: "GBR_NINO",
  },
  {
    name: "Guatemala NIT",
    value: "GTM_NIT",
  },
  {
    name: "Honduras RTN",
    value: "HND_RTN",
  },
  {
    name: "Hungary TIN Number",
    value: "HUN_TIN",
  },
  {
    name: "Indonesia KTP",
    value: "IDN_KTP",
  },
  {
    name: "India PAN Number",
    value: "IND_PAN",
  },
  {
    name: "Israel Tax ID (Teudat Zehut)",
    value: "ISR_TAX_ID",
  },
  {
    name: "Italy Tax ID (Codice Fiscale)",
    value: "ITA_TAX_ID",
  },
  {
    name: "Japan Tax ID (Koijin Bango)",
    value: "JPN_TAX_ID",
  },
  {
    name: "Mexico RFC",
    value: "MEX_RFC",
  },
  {
    name: "Nicaragua RUC",
    value: "NIC_RUC",
  },
  {
    name: "Netherlands TIN Number",
    value: "NLD_TIN",
  },
  {
    name: "Panama RUC",
    value: "PAN_RUC",
  },
  {
    name: "Peru RUC",
    value: "PER_RUC",
  },
  {
    name: "Paraguay RUC",
    value: "PRY_RUC",
  },
  {
    name: "Singapore NRIC",
    value: "SGP_NRIC",
  },
  {
    name: "Singapore FIN",
    value: "SGP_FIN",
  },
  {
    name: "Singapore ASGD",
    value: "SGP_ASGD",
  },
  {
    name: "Singapore ITR",
    value: "SGP_ITR",
  },
  {
    name: "El Salvador NIT",
    value: "SLV_NIT",
  },
  {
    name: "Sweden Tax ID (Personnummer)",
    value: "SWE_TAX_ID",
  },
  {
    name: "Uruguay RUT",
    value: "URY_RUT",
  },
  {
    name: "Venezuela RIF",
    value: "VEN_RIF",
  },
  {
    name: "Other Tax IDs",
    value: "NOT_SPECIFIED",
  },
];

export const fundingSources = [
  {
    name: "Employment income",
    value: "employment_income",
  },
  {
    name: "Investments",
    value: "investments",
  },
  {
    name: "Inheritance",
    value: "inheritance",
  },
  {
    name: "Business income",
    value: "business_income",
  },
  {
    name: "Savings",
    value: "savings",
  },
  {
    name: "Family",
    value: "family",
  },
];

export const documentTypes = [
  {
    name: "Identity verification",
    value: "identity_verification",
  },
  {
    name: "Address verification",
    value: "address_verification",
  },
  {
    name: "Inheritance",
    value: "inheritance",
  },
  {
    name: "Date of birth verification",
    value: "date_of_birth_verification",
  },
  {
    name: "Tax ID verification",
    value: "tax_id_verification",
  },
  {
    name: "407 approval letter",
    value: "account_approval_letter",
  },
  {
    name: "W-8 BEN tax form",
    value: "w8ben",
  },
];

export const USCitizenType = {
  yes: 'yes',
  greenCard: 'greenCard',
  onVisa: 'onVisa',
  no: 'no',
}

export const isUSCitizenOptions = [
  {
    name: "Yes",
    value: USCitizenType.yes,
  },
  {
    name: "I have a Green Card",
    value: USCitizenType.greenCard,
  },
  {
    name: "I’m here on a visa",
    value: USCitizenType.onVisa,
  },
  {
    name: "No",
    value: USCitizenType.no,
  },
];

export const visaTypes = [
  {
    name: "B1",
    value: "B1",
  },
  {
    name: "B2",
    value: "B2",
  },
  {
    name: "DACA",
    value: "DACA",
  },
  {
    name: "E1",
    value: "E1",
  },
  {
    name: "E2",
    value: "E2",
  },
  {
    name: "E3",
    value: "E3",
  },
  {
    name: "F1",
    value: "F1",
  },
  {
    name: "G4",
    value: "G4",
  },
  {
    name: "H1B",
    value: "H1B",
  },
  {
    name: "J1",
    value: "J1",
  },
  {
    name: "L1",
    value: "L1",
  },
  {
    name: "O1",
    value: "O1",
  },
  {
    name: "TN1",
    value: "TN1",
  },
  {
    name: "Other",
    value: "OTHER",
  },
];

export const incomeOptions = [
  { name: "< $20,000", value: "< $20,000" },
  { name: "$20,000 - $49,999", value: "$20,000 - $49,999" },
  { name: "$50,000 - $99,999", value: "$50,000 - $99,999" },
  { name: "$100,000 - $499,999", value: "$100,000 - $499,999" },
  { name: "> $500,000", value: "> $500,000" },
];

export const employmentValues = {
  employed: 'employed',
  unemployed: 'unemployed',
  student: 'student',
  retired: 'retired',
};

export const employmentOptions = [
  { name: "Employed", value: employmentValues.employed },
  { name: "Unemployed", value: employmentValues.unemployed },
  { name: "Student", value: employmentValues.student },
  { name: "Retired", value: employmentValues.retired },
];

export const fieldNames = {
  legalFirstName: "legalFirstName",
  legalMiddleName: "legalMiddleName",
  legalLastName: "legalLastName",
  isUSTaxResident: "isUSTaxResident",
  isCitizenOfUS: "isCitizenOfUS",
  visaType: "visaType",
  visaExpirationDate: "visaExpirationDate",
  dateOfDepartureFromUSA: "dateOfDepartureFromUSA",
  incomeValue: "incomeValue",
  assetValue: "assetValue",
  fundingValue: "fundingValue",
  employmentStatus: "employmentStatus",
  employerName: "employerName",
  employerAddress: "employerAddress",
  employerPosition: "employerPosition",
  streetAddress: "streetAddress",
  unitApartment: "unitApartment",
  city: "city",
  state: "state",
  postalCode: "postalCode",
  ssn: "ssn",
  givenName: "givenName",
  familyName: "familyName",
  dateOfBirth: "dateOfBirth",
  taxId: "taxId",
  taxIdType: "taxIdType",
  countryOfCitizenship: "countryOfCitizenship",
  countryOfBirth: "countryOfBirth",
  countryOfTaxResidency: "countryOfTaxResidency",
  fundingSource: "fundingSource",
  email: "email",
  address: "address",
  currentCity: "currentCity",
  currentState: "currentState",
  currentPostalCode: "currentPostalCode",
  currentCountry: "currentCountry",
  phoneNumber: "phoneNumber",
  trustedPersonName: "trustedPersonName",
  trustedPersonFamilyName: "trustedPersonFamilyName",
  trustedPersonEmail: "trustedPersonEmail",
  docType: "docType",
  doc: "doc",
  isControlPerson: "isControlPerson",
  isAffiliatedWithSEC_FINRA: "isAffiliatedWithSEC_FINRA",
  isPoliticallyExposed: "isPoliticallyExposed",
  isImmediateFamilyExposed: "isImmediateFamilyExposed",
};

export const onboardingTitlesOfSections = {
  LegalName: "Identity",
  DateOfBirth: "Identity",
  IsUSTaxResident: "Identity",
  IsUSCitizen: "Identity",
  SSN: "Identity",
  CountryOfCitizenshipAndBirth: "Identity",
  VisaDetails: "Identity",
  DateOfDeparture: "Identity",
  ContactInfo: "Contact information",
  DocumentSection: "Document",
  FinancialSituation: "Financial status",
  EmploymentStatus: "Financial status",
  EmployerInfo: "Financial status",
  Disclosures: "Disclosures",
  PoliticallyExposedPersonIdentity: "Disclosures",
  Confirmation: "Confirmation",
};

export const slideNames = {
  LegalName: "LegalName",
  DateOfBirth: "DateOfBirth",
  IsUSTaxResident: "IsUSTaxResident",
  IsUSCitizen: "IsUSCitizen",
  ContactInfo: "ContactInfo",
  FinancialSituation: "FinancialSituation",
  EmploymentStatus: "EmploymentStatus",
  Disclosures: "Disclosures",
  PoliticallyExposedPersonIdentity: "PoliticallyExposedPersonIdentity",
  EmployerInfo: "EmployerInfo",
  SSN: "SSN",
  CountryOfCitizenshipAndBirth: "CountryOfCitizenshipAndBirth",
  DateOfDeparture: "DateOfDeparture",
  VisaDetails: "VisaDetails",
  Confirmation: "Confirmation",
};

export const optionalFields = [
  slideNames.CountryOfCitizenshipAndBirth,
  slideNames.VisaDetails,
  slideNames.DateOfDeparture,
  slideNames.EmployerInfo,
  slideNames.PoliticallyExposedPersonIdentity,
];

export const isTaxIdValid = (value) => {
  return value && value.length === 9 ? null : "Tax ID should have length of 9.";
};

export const isGeneralTextFieldValid = (value) => {
  return value && value.length > 0 ? null : "Required";
};

export const isValidEmail = (value) => {
  return value && validator.isEmail(value) ? null : "Invalid email";
};

export const isValidPhoneNumber = (value) => {
  return value && validator.isMobilePhone(value)
    ? null
    : "Invalid phone number";
};

export const isAgeValid = (dob) => {
  const diff_ms = Date.now() - new Date(dob).getTime();
  const age_dt = new Date(diff_ms);
  const age = Math.abs(age_dt.getUTCFullYear() - 1970);
  return age >= 18;
};

export const isValidSSN = (value) => {
  return value.match(
    /\b(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}\b/
  );
};

export const isValidZipCode = (value) => {
  return value.length === 0
    ? "Required"
    : value.match(/^[0-9]{5}(?:-[0-9]{4})?$/)
      ? null
      : "Invalid ZIP code";
};

export const USStates = [
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

export const getDateFromTimestampByNYTimezone = (timeStamp, format) => {
  return moment(timeStamp).tz("America/New_York").format(format);
};

export const getDateFromTimestamp = (timeStamp, format) => {
  return moment(timeStamp).tz('UTC').format(format);
};
