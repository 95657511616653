import { useSelector } from 'react-redux';
import { organizationSelector } from '@store/organization/selectors';

const getFee = (organizationState) => {
  if (!organizationState || !organizationState.organizationInfo) {
    return 0;
  }
  const value = Number(organizationState.organizationInfo.monthly_fee_percent);
  return Number.isNaN(value) ? 0 : value;
};

const getEkoFee = (organizationState) => {
  const defaultEkoFee = 0.3;
  if (!organizationState || !organizationState.organizationInfo) {
    return defaultEkoFee;
  }
  const value = Number(organizationState.organizationInfo.head_monthly_fee_amount_percent);
  return (Number.isNaN(value) || !value) ? defaultEkoFee : value;
};

const useVendorFee = () => {
  const organizationState = useSelector(organizationSelector);
  return getFee(organizationState);
};

const useEkoFee = () => {
  const organizationState = useSelector(organizationSelector);
  return getEkoFee(organizationState);
};

export const useAnnualFee = () => {
  const vendorFee = useVendorFee();
  const ekoFee = useEkoFee();

  return vendorFee + ekoFee;
};
