import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useOnResize = (onResize: (width: number, height: number) => void, targetElement = document.body) => {
  const debouncedOnResize = useCallback(debounce(onResize, 300), []);

  const observer = new ResizeObserver((entries) => {
    const values = entries[0];
    if (values) {
      debouncedOnResize(values.contentRect.width, values.contentRect.height);
    }
  });

  // Call onResize once when element was mounted
  useEffect(() => {
    debouncedOnResize(targetElement.offsetWidth, targetElement.offsetHeight);
  }, [targetElement, debouncedOnResize]);

  // Call onResize once when element was mounted
  useEffect(() => {
    observer.observe(targetElement);
    return () => {
      observer.disconnect();
    };
  }, [targetElement, observer]);
};
