import styled from 'styled-components';

import { fontSize } from '~/ui/kit/constants/fonts';
import { gaps } from '~/ui/kit/constants/sizes';
import { Card } from '~/ui/kit/organisms/Card';

export const Container = styled(Card).attrs({
  fs$: 's'
})`
  width: 100%;
  margin-top: 30px;
  
  background-color: var(--eko-primary-color-light-6);
  padding: ${gaps.m}px 0;
  ${fontSize};
  color: var(--eko-footer-disclaimer-color);
`;
