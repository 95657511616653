import { setNotifications } from '@store/application/notifications/actions';
import { getDispatch, getState } from '@store/utils';

import { Notification, Notifications } from '@models/application/notifications';

import { sortNotifications } from '@services/application';

const isNotificationAbleToAdd = (notification: Notification, existing: Notifications) =>
  !existing.find(ex => ex.type === notification.type);

export const addNotification = (notification: Notification) => {
  const dispatch = getDispatch();
  const state = getState();
  const existing = state.Application.notifications;

  if (!isNotificationAbleToAdd(notification, existing)) return;

  const sorted = sortNotifications([...existing, notification]);

  dispatch(setNotifications(sorted));
};

export const hideNotification = (notification: Notification) => {
  const dispatch = getDispatch();
  const state = getState();
  const existing = state.Application.notifications;

  const notifications = existing.filter(ex => ex.type !== notification.type);

  if (notifications.length !== existing.length) {
    dispatch(setNotifications(notifications));
  }
};
