import styled from 'styled-components';
import { constVoid } from 'fp-ts/function';

import { fontSize } from '~/ui/kit/constants/fonts';
import { Popup } from '~/ui/kit/organisms/Popup';
import { gaps } from '~/ui/kit/constants/sizes';
import * as PopupStyles from '~/ui/kit/organisms/Popup/Popup.organism.styled';
import { Input } from '~/ui/kit/molecules/Input';

export const Container = styled(Popup).attrs({
  disableClose: true,
  title: null,
  onClickNo: constVoid,
  yesBtnText: 'Ok',
  isLoading: false,
  zIndex: 1051
})`
  --bs-modal-width: 475px;

  & .modal-content {
    padding: ${gaps.xl}px;
  }

  ${PopupStyles.Confirm} {
    max-width: unset;
    width: 100%;
  }
`;

export const Content = styled.div.attrs({ $fs: 'xs' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${gaps.m}px;
  ${fontSize};
  text-align: center;
`;

export const DontShowAgainCheckbox = styled(Input.Checkbox)``;
