import * as RD from '@devexperts/remote-data-ts';
import { sequenceS as sequenceSFactory, sequenceT as sequenceTFactory } from 'fp-ts/Apply';
import { array } from 'fp-ts/Array';
import { Eq } from 'fp-ts/Eq';
import { Lazy, pipe } from 'fp-ts/function';
import { Option, fold as oFold } from 'fp-ts/Option';

const sequenceS = sequenceSFactory(RD.remoteData);

const sequenceT = sequenceTFactory(RD.remoteData);

const sequenceArrayRD = array.sequence(RD.remoteData);

const getDataEq = <T, E extends {message?: string}>(eq: Eq<T>) => RD.getEq<E, T>({ equals: (a, b) => a.message === b.message }, eq);

const fromOption = <E, V>(onNone: Lazy<RD.RemoteData<E, V>>) => (option: Option<V>) =>
  pipe(option, oFold(onNone, RD.success));

const altOnError = <E, A>(that: Lazy<RD.RemoteData<E, A>>) => (fa: RD.RemoteData<E, A>): RD.RemoteData<E, A> => {
  if (fa._tag === 'RemoteFailure') {
    return that();
  }
  return fa;
};

export { sequenceS, sequenceT, getDataEq, sequenceArrayRD, fromOption, altOnError };
